import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import moment from "moment";

/* eslint-disable jsx-a11y/label-has-associated-control */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import dayjs from "dayjs";

// MS
import service from "../../services/apiMS/index";

import validator from "./validateInsurance";

const initialValues = {
  insurance_id: "",
  insurance_company_id: "",
  insurance_company_name: "",
  insurance_name: "",
  insurance_number: "",
  insurance_status: "",
  insurance_created: "",
  insurance_start: null,
  insurance_expire: null,
  insurance_vehicle_brand: "",
  insurance_vehicle_model: "",
  insurance_vehicle_year: "",
  insurance_vehicle_chasis: "",
};

function FormInsurance() {
  const { insuranceId } = useParams();
  const navigate = useNavigate();
  const [saveResponse, setSaveResponse] = useState(null);
  const [insurancesCompanies, setInsurancesCompanies] = useState([]);
  const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState({});
  const [dataProcessing, setDataProcessing] = useState(false);

  const loading = dataProcessing && insurancesCompanies.length === 0;

  const { handleSubmit, handleChange, values, resetForm, errors, setFieldValue } = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      const saveInsuranceResponse = await service.saveInsurance({ userData, formValues });

      if (saveInsuranceResponse.ok) {
        setSaveResponse(true);
      }
    },
    validate: (v) => validator(v),
  });

  useEffect(() => {
    const getInsurance = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);

      const insuranceResponse = await service.getInsurance({ userData, insuranceId });

      if (!insuranceResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (insuranceResponse.ok) {
        const insuranceInfoData = await insuranceResponse.json();
        resetForm({
          values: {
            insurance_id: insuranceInfoData.id,
            insurance_company_id: insuranceInfoData.insurance_company.id,
            insurance_company_name: insuranceInfoData.insurance_company.name,
            insurance_name: insuranceInfoData.name,
            insurance_number: insuranceInfoData.number,
            insurance_status: insuranceInfoData.status,
            insurance_created: moment(insuranceInfoData.created_at).format("DD/MM/YYYY"),
            insurance_start:
              insuranceInfoData.start_at && insuranceInfoData.start_at !== null
                ? dayjs(insuranceInfoData.start_at)
                : null,
            insurance_expire:
              insuranceInfoData.expire_at && insuranceInfoData.expire_at !== null
                ? dayjs(insuranceInfoData.expire_at)
                : null,
            insurance_vehicle_brand: insuranceInfoData.vehicle?.brand ?? "Sin datos",
            insurance_vehicle_model: insuranceInfoData.vehicle?.model ?? "Sin datos",
            insurance_vehicle_year: insuranceInfoData.vehicle?.year ?? "Sin datos",
            insurance_vehicle_chasis: insuranceInfoData.vehicle?.chassis_number ?? "Sin datos",
          },
        });
      }
    };
    getInsurance();
  }, [navigate, resetForm, insuranceId]);

  useEffect(() => {
    const getInsuranceCompanies = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const insurancesCompaniesResponse = await service.getInsuranceCompanies({ userData });

      if (!insurancesCompaniesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (insurancesCompaniesResponse.ok) {
        const insurancesCompaniesInfoData = await insurancesCompaniesResponse.json();
        setInsurancesCompanies(insurancesCompaniesInfoData);
      }
      setDataProcessing(false);
    };
    if (values.insurance_company_id) {
      getInsuranceCompanies();
    }
  }, [navigate, values.insurance_company_id]);

  const insurancesCompaniesAsJSON = JSON.stringify(insurancesCompanies);

  useEffect(() => {
    const insuranceCompanyValue = JSON.parse(insurancesCompaniesAsJSON);
    const foundInsuranceCompany = insuranceCompanyValue.find(
      (insuranceCompany) => insuranceCompany.id === values.insurance_company_id
    );
    setSelectedInsuranceCompany(foundInsuranceCompany);
  }, [insurancesCompaniesAsJSON, values.insurance_company_id]);

  const readOnly = values.insurance_status !== "pending" && values.insurance_status !== "";

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Editar póliza
            </MDTypography>
          </MDBox>
          <form action="" className="form-container" onSubmit={handleSubmit}>
            {saveResponse ? <Alert severity="success">Póliza actualizada</Alert> : ""}

            {readOnly && (
              <Alert severity="warning" sx={{ mb: 2 }}>
                No es posible editar esta póliza.
              </Alert>
            )}

            <MDBox mt={4} mb={4} mx={6} pt={3} py={3} px={2}>
              <Grid container spacing={4} display="flex" flexDirection="row">
                <Grid item xs={12} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    id="insurance_company_id"
                    name="insurance_company_id"
                    value={selectedInsuranceCompany}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => {
                      setFieldValue("insurance_company_id", value?.id);
                      setFieldValue("insurance_company_name", value?.name);
                    }}
                    options={insurancesCompanies?.map((insuranceCompany) => ({
                      name: insuranceCompany.name,
                      id: insuranceCompany.id,
                    }))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Aseguradora"
                        variant="standard"
                        disabled={readOnly}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Nombre"
                    variant="standard"
                    disabled={readOnly}
                    id="insurance_name"
                    name="insurance_name"
                    value={values.insurance_name}
                    onChange={handleChange}
                    error={!!errors.insurance_name}
                    helperText={errors.insurance_name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Número"
                    variant="standard"
                    disabled
                    id="insurance_number"
                    name="insurance_number"
                    value={values.insurance_number}
                    onChange={handleChange}
                    error={!!errors.insurance_number}
                    helperText={errors.insurance_number}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Creación"
                    variant="standard"
                    disabled
                    id="insurance_created"
                    name="insurance_created"
                    value={values.insurance_created}
                    onChange={handleChange}
                    error={!!errors.insurance_created}
                    helperText={errors.insurance_created}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]} sx={{ overflow: "hidden" }}>
                      <DatePicker
                        label="Fecha Inicio"
                        value={values.insurance_start}
                        disabled={readOnly}
                        format="DD/MM/YYYY"
                        onChange={(newDate) => {
                          setFieldValue("insurance_start", newDate);
                        }}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]} sx={{ overflow: "hidden" }}>
                      <DatePicker
                        label="Fecha Fin"
                        value={values.insurance_expire}
                        disabled={readOnly}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { size: "small" } }}
                        onChange={(newDate) => {
                          setFieldValue("insurance_expire", newDate);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    label="Estado"
                    variant="standard"
                    disabled
                    id="insurance_status"
                    name="insurance_status"
                    value={values.insurance_status}
                    onChange={handleChange}
                    error={!!errors.insurance_status}
                    helperText={errors.insurance_status}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <MDBox mt={4} mb={6} pt={3} py={3}>
                <Grid container spacing={4} display="flex" flexDirection="row">
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      label="Marca"
                      variant="standard"
                      disabled
                      id="insurance_vehicle_brand"
                      name="insurance_vehicle_brand"
                      value={values.insurance_vehicle_brand}
                      onChange={handleChange}
                      error={!!errors.insurance_vehicle_brand}
                      helperText={errors.insurance_vehicle_brand}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      label="Modelo"
                      variant="standard"
                      disabled
                      id="insurance_vehicle_model"
                      name="insurance_vehicle_model"
                      value={values.insurance_vehicle_model}
                      onChange={handleChange}
                      error={!!errors.insurance_vehicle_model}
                      helperText={errors.insurance_vehicle_model}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      label="Año"
                      variant="standard"
                      disabled
                      id="insurance_vehicle_year"
                      name="insurance_vehicle_year"
                      value={values.insurance_vehicle_year}
                      onChange={handleChange}
                      error={!!errors.insurance_vehicle_year}
                      helperText={errors.insurance_vehicle_year}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <TextField
                      label="Nro. Chasis"
                      variant="standard"
                      disabled
                      id="insurance_vehicle_chasis"
                      name="insurance_vehicle_chasis"
                      value={values.insurance_vehicle_chasis}
                      onChange={handleChange}
                      error={!!errors.insurance_vehicle_chasis}
                      helperText={errors.insurance_vehicle_chasis}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={6} display="flex" flexDirection="row-reverse">
                <Stack direction="row" spacing={2}>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    bgColor="dark"
                    borderRadius="lg"
                    coloredShadow="dark"
                    onClick={() => navigate(`/insurance/${values.insurance_id}`)}
                  >
                    <Icon>undo</Icon>&nbsp; volver
                  </MDButton>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="success"
                    bgColor="dark"
                    borderRadius="lg"
                    coloredShadow="dark"
                    onSubmit={handleSubmit}
                    disabled={readOnly}
                  >
                    <Icon>save</Icon>&nbsp; guardar
                  </MDButton>
                </Stack>
              </MDBox>
            </MDBox>
          </form>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default FormInsurance;
