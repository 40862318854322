// const usPhoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;
const nameRegex = /^([a-zA-Z\sÁÉÍÓÚáéíóúÑñ]+)$/;
const emailRegExp = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export default (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = "El usuario es requerido";
  }

  if (!values.password) {
    errors.password = "La contraseña es requerida";
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = "La confirmación de contraseña es requerida";
  }

  if (values.password_confirmation !== values.password) {
    errors.password_confirmation = "La confirmación de contraseña debe ser igual a la contraseña";
  }

  if (!values.first_name) {
    errors.first_name = "El nombre es requerido";
  }

  if (values.first_name && !nameRegex.test(values.first_name)) {
    errors.first_name = "Formato inválido";
  }

  if (!values.last_name) {
    errors.last_name = "El apellido es requerido";
  }
  if (values.last_name && !nameRegex.test(values.last_name)) {
    errors.last_name = "Formato inválido";
  }
  if (!values.document_number) {
    errors.document_number = "El documento es requerido";
  }
  if (!values.dob) {
    errors.dob = "La fecha de nacimiento es requerida";
  }
  if (!values.email) {
    errors.email = "El email es requerido";
  }
  if (values.email && !emailRegExp.test(values.email)) {
    errors.email = "El email no es válido";
  }

  if (!values.address) {
    errors.address = "La dirección es requerida";
  }

  if (!values.roles?.length) {
    errors.roles = "El rol es requerido";
  }

  return errors;
};
