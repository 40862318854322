export default (values) => {
  const errors = {};

  if (!values.insurance_company_name) {
    errors.insurance_company_name = "La aseguradora es requerida";
  }

  if (!values.insurance_name) {
    errors.insurance_name = "El nombre es requerido";
  }

  return errors;
};
