/** 
  All of the routes are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import SignIn from "layouts/authentication/sign-in";
import PasswordRecovery from "layouts/authentication/password-recovery";
import Dashboard from "layouts/dashboard";
import FormInsurance from "layouts/formInsurance";
import FormUser from "layouts/formUser";
import FormVehicle from "layouts/formVehicle";
import Insurance from "layouts/insurance";
import Insurances from "layouts/insurances";
import NewUser from "layouts/newUser";
import Roles from "layouts/roles";
import User from "layouts/user";
import Users from "layouts/users";
import Vehicle from "layouts/vehicle";
import Vehicles from "layouts/vehicles";
import InsurancesVehicle from "layouts/insurancesVehicle";
import Tips from "layouts/tips";
import FormTip from "layouts/formTip";
import Profile from "layouts/profile";
import CreateTip from "layouts/createTip";

import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Login",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "PasswordRecovery",
    key: "password-recovery",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/password-recovery",
    component: <PasswordRecovery />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "FormInsurance",
    key: "formInsurance",
    icon: <Icon fontSize="small">car</Icon>,
    route: "/forminsurance/:insuranceId",
    component: <FormInsurance />,
  },
  {
    type: "collapse",
    name: "FormUser",
    key: "formUser",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/formuser/:userId",
    component: <FormUser />,
  },
  {
    type: "collapse",
    name: "FormVehicle",
    key: "formVehicle",
    icon: <Icon fontSize="small">car</Icon>,
    route: "/formvehicle/:vehicleId",
    component: <FormVehicle />,
  },
  {
    type: "collapse",
    name: "Póliza",
    key: "insurance",
    icon: <Icon fontSize="small">content_copy</Icon>,
    route: "/insurance/:insuranceId",
    component: <Insurance />,
  },
  {
    type: "collapse",
    name: "Pólizas",
    key: "insurances",
    icon: <Icon fontSize="small">content_copy</Icon>,
    route: "/insurances",
    component: <Insurances />,
  },
  {
    type: "collapse",
    name: "Crear usuario",
    key: "newUser",
    icon: <Icon fontSize="small">user</Icon>,
    route: "/newUser",
    component: <NewUser />,
  },
  {
    type: "collapse",
    name: "Usuario",
    key: "user",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user/:userId",
    component: <User />,
  },
  {
    type: "collapse",
    name: "Usuarios",
    key: "users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Vehículo",
    key: "vehicle",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/vehicle/:vehicleId",
    component: <Vehicle />,
  },
  {
    type: "collapse",
    name: "Vehículos",
    key: "vehicles",
    icon: <Icon fontSize="small">directions_car</Icon>,
    route: "/vehicles",
    component: <Vehicles />,
  },
  {
    type: "collapse",
    name: "Pólizas por vehículo",
    key: "insurancesVehicles",
    icon: <Icon fontSize="small">content_copy</Icon>,
    route: "/insurancesVehicles/:vehicleId",
    component: <InsurancesVehicle />,
  },
  {
    type: "collapse",
    name: "Tips",
    key: "tips",
    icon: <Icon fontSize="small">check_box</Icon>,
    route: "/tips",
    component: <Tips />,
  },
  {
    type: "collapse",
    name: "Roles",
    key: "rol",
    icon: <Icon fontSize="small">groups</Icon>,
    route: "/roles",
    component: <Roles />,
  },
  {
    type: "collapse",
    name: "Tip",
    key: "tip",
    icon: <Icon fontSize="small">check</Icon>,
    route: "/formtip/:tipId",
    component: <FormTip />,
  },
  {
    type: "collapse",
    name: "Perfil",
    key: "profile",
    icon: <Icon fontSize="small">check</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Crear Tip",
    key: "createtip",
    icon: <Icon fontSize="small">check</Icon>,
    route: "/newtip",
    component: <CreateTip />,
  },
];

export default routes;
