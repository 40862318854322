import { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";

/* eslint-disable jsx-a11y/label-has-associated-control */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";
import AddPhotoIcon from "@mui/icons-material/AddAPhoto";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import validator from "./validateNewUser";

// MS
import service from "../../services/apiMS/index";

const initialValues = {
  username: "",
  password: "",
  password_confirmation: "",
  first_name: "",
  last_name: "",
  document_type_id: "",
  document_number: "",
  dob: dayjs(Date.now()),
  email: "",
  address: "",
  phone: "",
  sex_id: "",
  tax_condition_id: "",
  city_id: "",
  state_id: "",
  profile_picture: null,
  roles: [],
};

function FormUser() {
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [docTypes, setDocTypes] = useState([]);
  const [gender, setGender] = useState([]);
  const [selectedGender, setSelectedGender] = useState({});
  const [taxCondition, setTaxCondition] = useState([]);
  const [selectedTaxCondition, setSelectedTaxCondition] = useState({});
  const [selectedDocType, setSelectedDocType] = useState({});
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [sendProfilePicture, setSendProfilePicture] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [showToastOk, setShowToastOk] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [saveError, setSaveError] = useState();
  const loading = dataProcessing && states.length === 0;

  const { handleSubmit, handleChange, handleBlur, touched, values, errors, setFieldValue } =
    useFormik({
      initialValues,
      onSubmit: async (formValues) => {
        const userDataString = localStorage.getItem("userData");

        if (!userDataString) {
          navigate("/authentication/sign-in");
          return;
        }

        const userData = JSON.parse(userDataString);
        const userRoles = selectedRoles.map((rol) => rol.id);

        const formValuesToSend = {
          ...formValues,
          role_ids: userRoles,
          profile_picture: sendProfilePicture ? formValues.profile_picture : undefined,
        };

        setSaveLoading(true);

        const saveUserResponse = await service.newUser({ userData, formValues: formValuesToSend });
        const saveUserResponseJson = await saveUserResponse.json();
        if (saveUserResponse.ok) {
          setShowToastOk(true);
        } else {
          setSaveError(saveUserResponseJson.message);
          setShowToastError(true);
        }

        setSaveLoading(false);
      },
      validate: (v) => validator(v),
    });

  useEffect(() => {
    const getStates = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const statesResponse = await service.getStates({ userData });

      if (!statesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (statesResponse.ok) {
        const statesInfoData = await statesResponse.json();
        setStates(statesInfoData);
      }
      setDataProcessing(false);
    };
    getStates();
  }, [navigate]);

  useEffect(() => {
    const getCities = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const citiesResponse = await service.getCities({ userData, stateId: values.state_id });

      if (!citiesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (citiesResponse.ok) {
        const citiesInfoData = await citiesResponse.json();
        setCities(citiesInfoData.cities);
      }
      setDataProcessing(false);
    };

    if (values.state_id) {
      getCities();
    }
  }, [navigate, values.state_id]);

  useEffect(() => {
    const getDocTypes = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const docTypesResponse = await service.getDocTypes({ userData });

      if (!docTypesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (docTypesResponse.ok) {
        const docTypesInfoData = await docTypesResponse.json();
        setDocTypes(docTypesInfoData);
      }
      setDataProcessing(false);
    };

    getDocTypes();
  }, [navigate]);

  useEffect(() => {
    const getGenders = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const genderResponse = await service.getGenders({ userData });

      if (!genderResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (genderResponse.ok) {
        const genderInfoData = await genderResponse.json();
        setGender(genderInfoData);
      }
      setDataProcessing(false);
    };

    getGenders();
  }, [navigate]);

  useEffect(() => {
    const getTaxCondition = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const taxResponse = await service.getTaxCondition({ userData });

      if (!taxResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (taxResponse.ok) {
        const taxInfoData = await taxResponse.json();
        setTaxCondition(taxInfoData);
      }
      setDataProcessing(false);
    };

    getTaxCondition();
  }, [navigate]);

  useEffect(() => {
    const getRoles = async () => {
      const userDataString = localStorage.getItem("userData");

      if (!userDataString) {
        navigate("/authentication/sign-in");
        return;
      }

      const userData = JSON.parse(userDataString);
      setDataProcessing(true);

      const rolesResponse = await service.getRoles({ userData, pageIndex: 1 });

      if (!rolesResponse.ok) {
        navigate("/authentication/sign-in");
      }

      if (rolesResponse.ok) {
        const rolesInfoData = await rolesResponse.json();
        setRoles(rolesInfoData.data);
      }
      setDataProcessing(false);
    };
    getRoles();
  }, [navigate]);

  // eslint-disable-next-line no-unused-vars

  const statesAsJSON = JSON.stringify(states);

  useEffect(() => {
    const statesValue = JSON.parse(statesAsJSON);
    const foundState = statesValue.find((state) => state.id === values.state_id);
    setSelectedState(foundState);
  }, [statesAsJSON, values.state_id]);

  const citiesAsJSON = JSON.stringify(cities);

  useEffect(() => {
    const citiesValue = JSON.parse(citiesAsJSON);
    const foundCity = citiesValue.find((city) => city.id === values.city_id);
    setSelectedCity(foundCity);
  }, [citiesAsJSON, values.city_id]);

  const typesAsJSON = JSON.stringify(docTypes);

  useEffect(() => {
    const docTypeValue = JSON.parse(typesAsJSON);
    const foundType = docTypeValue.find((type) => type.id === values.document_type_id);
    setSelectedDocType(foundType);
  }, [typesAsJSON, values.document_type_id]);

  const genderAsJSON = JSON.stringify(gender);

  useEffect(() => {
    const genderValue = JSON.parse(genderAsJSON);
    const foundGender = genderValue.find((sex) => sex.id === values.sex_id);
    setSelectedGender(foundGender);
  }, [genderAsJSON, values.sex_id]);

  const taxConditionAsJSON = JSON.stringify(taxCondition);

  useEffect(() => {
    const taxConditionValue = JSON.parse(taxConditionAsJSON);
    const foundTaxCondition = taxConditionValue.find((tax) => tax.id === values.tax_condition_id);
    setSelectedTaxCondition(foundTaxCondition);
  }, [taxConditionAsJSON, values.tax_condition_id]);

  const rolesAsJSON = JSON.stringify(roles);

  useEffect(() => {
    const rolesValue = JSON.parse(rolesAsJSON);
    const foundRoles = rolesValue.filter((rol) => rol.id === values.id);
    setSelectedRoles(foundRoles);
  }, [rolesAsJSON, values.id]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFieldValue("profile_picture", file);
      setSendProfilePicture(true);
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Nuevo usuario
            </MDTypography>
          </MDBox>
          <form action="" className="form-container" onSubmit={handleSubmit}>
            {showToastOk && (
              <Collapse in={showToastOk}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setShowToastOk(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  Usuario creado
                </Alert>
              </Collapse>
            )}
            {showToastError && (
              <Collapse in={showToastError}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setShowToastError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="error"
                  sx={{ mb: 2 }}
                >
                  {saveError}
                </Alert>
              </Collapse>
            )}
            <MDBox display="flex" mt={4} mb={6} mx={6} pt={3} py={3} sm={12} md={6} lg={4}>
              <MDBox display="flex" mr={2}>
                <Grid>
                  <div
                    {...getRootProps()}
                    className={`dropzone ${isDragActive ? "drag-active" : ""}`}
                  >
                    <input {...getInputProps()} />

                    <Box
                      component="img"
                      sx={{
                        height: 250,
                        width: 250,
                        maxHeight: { xs: 200, md: 250 },
                        maxWidth: { xs: 200, md: 250 },
                        p: 1,
                        border: "1px dashed grey",
                      }}
                      src={selectedImage ?? values.profile_picture}
                    />

                    <IconButton>
                      <AddPhotoIcon />
                    </IconButton>
                  </div>
                </Grid>
              </MDBox>
              <MDBox display="flex">
                <Grid container spacing={3}>
                  <Grid item sm={12} md={12}>
                    <Grid container spacing={3}>
                      <Grid item sm={6} md={6}>
                        <TextField
                          label="Usuario"
                          variant="standard"
                          id="username"
                          name="username"
                          fullWidth
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.username && errors.username}
                          helperText={touched.username && errors.username ? errors.username : ""}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4} md={6}>
                    <TextField
                      label="Contraseña"
                      variant="standard"
                      id="password"
                      name="password"
                      fullWidth
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && errors.password}
                      helperText={touched.password && errors.password ? errors.password : ""}
                    />
                  </Grid>
                  <Grid item sm={4} md={6}>
                    <TextField
                      label="Confirmar Contraseña"
                      variant="standard"
                      id="password_confirmation"
                      name="password_confirmation"
                      fullWidth
                      value={values.password_confirmation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password_confirmation && errors.password_confirmation}
                      helperText={
                        touched.password_confirmation && errors.password_confirmation
                          ? errors.password_confirmation
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item sm={4} md={6}>
                    <TextField
                      label="Nombre"
                      variant="standard"
                      id="first_name"
                      name="first_name"
                      fullWidth
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.first_name && errors.first_name}
                      helperText={touched.first_name && errors.first_name ? errors.first_name : ""}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Apellido"
                      variant="standard"
                      id="last_name"
                      name="last_name"
                      fullWidth
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.last_name && errors.last_name}
                      helperText={touched.last_name && errors.last_name ? errors.last_name : ""}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="document_type_id"
                      name="document_type_id"
                      value={selectedDocType}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option.value}
                      onChange={(_, value) => {
                        setFieldValue("document_type_id", value?.id);
                      }}
                      options={docTypes?.map((type) => ({
                        value: type.value,
                        id: type.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo Doc"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Documento"
                      variant="standard"
                      id="document_number"
                      name="document_number"
                      fullWidth
                      value={values.document_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.document_number && errors.document_number}
                      helperText={
                        touched.document_number && errors.document_number
                          ? errors.document_number
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture
                        label="Fecha de nacimiento"
                        value={values.dob}
                        format="DD-MM-YYYY"
                        onChange={(value) => {
                          setFieldValue("dob", value, false);
                        }}
                        slotProps={{
                          textField: {
                            variant: "standard",
                            fullWidth: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      id="sex_id"
                      name="sex_id"
                      value={selectedGender}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option.value}
                      onChange={(_, value) => {
                        setFieldValue("sex_id", value?.id);
                      }}
                      options={gender?.map((sex) => ({
                        value: sex.value,
                        id: sex.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Género"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      id="tax_condition_id"
                      name="tax_condition_id"
                      value={selectedTaxCondition}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option.value}
                      onChange={(_, value) => {
                        setFieldValue("tax_condition_id", value?.id);
                      }}
                      options={taxCondition?.map((tax) => ({
                        value: tax.value,
                        id: tax.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Condición frente al IVA"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Email"
                      variant="standard"
                      fullWidth
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && errors.email}
                      helperText={touched.email && errors.email ? errors.email : ""}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Dirección"
                      variant="standard"
                      fullWidth
                      id="address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.address && errors.address}
                      helperText={touched.address && errors.address ? errors.address : ""}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      label="Teléfono"
                      variant="standard"
                      fullWidth
                      id="phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phone && errors.phone}
                      helperText={touched.phone && errors.phone ? errors.phone : ""}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="state_id"
                      name="state_id"
                      value={selectedState}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value) => {
                        setFieldValue("state_id", value?.id);
                      }}
                      options={states?.map((state) => ({
                        name: state.name,
                        id: state.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Provincia"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="city_id"
                      name="city_id"
                      value={selectedCity}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value) => {
                        setFieldValue("city_id", value?.id);
                      }}
                      options={cities?.map((city) => ({
                        name: city.name,
                        id: city.id,
                      }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ciudad"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      multiple
                      id="roles"
                      limitTags={1}
                      value={selectedRoles}
                      onChange={(event, newValue) => {
                        setSelectedRoles([...newValue]);
                        setFieldValue("roles", [...newValue]);
                      }}
                      options={roles}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label="Roles"
                          variant="standard"
                          error={touched.roles && errors.roles}
                          helperText={touched.roles && errors.roles ? errors.roles : ""}
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <MenuItem
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...props}
                          key={option.name}
                          value={option.name}
                          sx={{ justifyContent: "space-between" }}
                        >
                          {option.name}
                          {selected ? <CheckIcon color="info" /> : null}
                        </MenuItem>
                      )}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="row-reverse"
              mt={2}
              mb={2}
              mx={6}
              pt={3}
              py={3}
              px={2}
              sm={12}
              md={6}
              lg={8}
            >
              <Stack direction="row" spacing={2}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="dark"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  onClick={() => navigate(-1)}
                >
                  <Icon>undo</Icon>&nbsp; volver
                </MDButton>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="success"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  onSubmit={handleSubmit}
                  sx={{ minWidth: "8rem" }}
                >
                  {saveLoading ? (
                    <CircularProgress color="inherit" size="1.2rem" />
                  ) : (
                    <>
                      <Icon>save</Icon>&nbsp; guardar
                    </>
                  )}
                </MDButton>
              </Stack>
            </MDBox>
          </form>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default FormUser;
